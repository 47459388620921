<template>
    <div class="tipo equipo">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-container>
            <v-card>
                <v-dialog v-model="modalTipoEquipo" max-width="500px" persistent>
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <v-card-title>
                                <span class="text-h5">{{ tituloTipoEquipo }}</span>
                            </v-card-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-form ref="frmTipoEquipo" v-model="validoTipoEquipo" lazy-validation>
                                    <v-text-field v-model="editTipoEquipo.nombre" :rules="reglaNombreTipoEquipo"
                                        prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                    </v-text-field>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" class="mr-4" @click="cancelarTipoEquipo">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!validoTipoEquipo" color="success" class="mr-4"
                                            @click="guardarTipoEquipo">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="modalEliminarTipoEquipo" max-width="550px">
                    <v-card>
                        <v-card-title class="text-h6 font-weight-ligth text--secondary">
                            <v-icon x-large left color="error">
                                mdi-alert-octagon-outline
                            </v-icon>
                            ¿Estas seguro que deceas eliminar este campo?
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="cerrarEliminarTipoEquipo">
                                Cancelar
                            </v-btn>
                            <v-btn color="success" @click="confirmarElimTipoEquipo">
                                Aceptar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- tabla -->
                <tabla :header="headersTipoEquipo" :body="tiposEquipos" tituloTabla="TIPO EQUIPO" :accionAgregar="true"
                    :exportar="true" @recargar="listarTipoEquipo" @agregar="agregarTipoEquipo"
                    @actualizar="editarTipoEquipo" @eliminar="eliminarTipoEquipo" />
                <!-- tabla fin -->
            </v-card>
        </v-container>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "tipo equipo",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables tipo equipo 
            UidTipoEquipo: null,
            buscarTipoEquipo: '',
            validoTipoEquipo: true,
            modalTipoEquipo: false,
            modalEliminarTipoEquipo: false,
            headersTipoEquipo: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            tiposEquipos: [],
            reglaNombreTipoEquipo: [
                v => !!v || 'El nombre del tipo es obligatorio.'
            ],
            editedTipoEquipoIndex: -1,
            editTipoEquipo: {
                nombre: ''
            },
            defaultTipoEquipo: {
                nombre: ''
            },
            // fin variables tipo equipo
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal tipo equipo 
            tituloTipoEquipo() {
                return this.editedTipoEquipoIndex === -1 ? 'Nuevo Tipo' : 'Editar Tipo'
            },
        },

        watch: {
            // modal tipo equipo
            modalTipoEquipo(val) {
                val || this.cancelarTipoEquipo()
            },
            modalEliminarTipoEquipo(val) {
                val || this.cerrarEliminarTipoEquipo()
            },
            // fin modal tipo equipo
        },

        created() {
            // listar tiposEquipos
            this.listarTipoEquipo()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarTipoEquipo() {
                try {
                    await fetch(`${this.url_api}/tipo_equipo`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.tiposEquipos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarTipoEquipo() {
                this.modalTipoEquipo = true
            },

            editarTipoEquipo(tipo) {
                this.editedTipoEquipoIndex = this.tiposEquipos.indexOf(tipo)
                this.editTipoEquipo = Object.assign({}, tipo)
                this.modalTipoEquipo = true
                this.UidTipoEquipo = tipo.Uid;
            },

            eliminarTipoEquipo(tipo) {
                this.editedTipoEquipoIndex = this.tiposEquipos.indexOf(tipo)
                this.editTipoEquipo = Object.assign({}, tipo)
                this.modalEliminarTipoEquipo = true
                this.UidTipoEquipo = tipo.Uid;
            },

            async confirmarElimTipoEquipo() {
                try {
                    await fetch(`${this.url_api}/tipo_equipo/${this.UidTipoEquipo}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.tiposEquipos.splice(this.editedTipoEquipoIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarTipoEquipo()
            },

            cancelarTipoEquipo() {
                this.$refs.frmTipoEquipo.reset()
                this.$refs.frmTipoEquipo.resetValidation()
                this.modalTipoEquipo = false
                this.$nextTick(() => {
                    this.editTipoEquipo = Object.assign({}, this.defaultTipoEquipo)
                    this.editedTipoEquipoIndex = -1
                })
            },

            cerrarEliminarTipoEquipo() {
                this.modalEliminarTipoEquipo = false
                this.$nextTick(() => {
                    this.editTipoEquipo = Object.assign({}, this.defaultTipoEquipo)
                    this.editedTipoEquipoIndex = -1
                })
            },

            async guardarTipoEquipo() {
                if (this.$refs.frmTipoEquipo.validate()) {
                    if (this.editedTipoEquipoIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/tipo_equipo/${this.UidTipoEquipo}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editTipoEquipo)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.tiposEquipos[this.editedTipoEquipoIndex], this
                                            .editTipoEquipo);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidTipoEquipo = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/tipo_equipo`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editTipoEquipo)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarTipoEquipo();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al guardar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarTipoEquipo()
                }
            },
            // fin CRUD tiposEquipos
        },
    }
</script>